import { Component } from "@angular/core";

import { UsersServices } from "../services/users/users.services";

@Component({
  selector: 'subscribe-newsletter-directive',
  templateUrl: 'subscribe.newsletter.directive.html'
})
export class SubscribeNewsletterDirective {

  public email: string;

  constructor(private usersServices: UsersServices) {}

  public subscribeNewsletter() {
    console.log('SubscribeNewsletterDirective.subscribeNewsletter: Start to subscribe on newsletter for', this.email);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (this.email && emailRegex.test(this.email)) {
      console.log('SubscribeNewsletterDirective.subscribeNewsletter: Email is valid', this.email);
      this.usersServices.subscribeToNewsletter(this.email).then((data: boolean)=> {
        console.log('SubscribeNewsletterDirective.subscribeNewsletter: Subscribed', data);
      });
    } else {
      console.error('SubscribeNewsletterDirective.subscribeNewsletter: Invalid email address');
      this.email = null;
    }
  }

}