<div class="container-xxl bg-white p-0">
    <!-- Navbar & Hero Start -->
    <div class="container-xxl position-relative p-0">
        <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
            <a href="" class="navbar-brand p-0">
                <h1 class="m-0">SG BIM</h1>
                <!-- <img src="assets/images/logo.png" alt="Logo"> -->
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span class="fa fa-bars"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <div class="navbar-nav mx-auto py-0">
                    <a [routerLink]="['/']" class="nav-item nav-link">Home</a>
                    <a [routerLink]="['/services']" class="nav-item nav-link">Services</a>
                    <a [routerLink]="['/pricing']" class="nav-item nav-link active">Pricing</a>
                    <!--
                    <div class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                        <div class="dropdown-menu m-0">
                            <a href="team.html" class="dropdown-item">Our Team</a>
                            <a href="testimonial.html" class="dropdown-item">Testimonial</a>
                            <a href="404.html" class="dropdown-item">404 Page</a>
                        </div>
                    </div>
                    -->
                    <a href="https://app.sg-bim.com" target="_blank" class="nav-item nav-link">Login</a>
                </div>
                <a [routerLink]="['/pricing']" class="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block">Get Started</a>
            </div>
        </nav>

        <div class="container-xxl bg-primary hero-header">
            <div class="container px-lg-5">
                <div class="row g-5 align-items-end">
                    <div class="col-lg-6 text-center text-lg-start">
                        <h1 class="text-white mb-4 animated slideInDown">Building Excellence with BIM Unleash Your Construction Potential!</h1>
                        <p class="text-white pb-3 animated slideInDown">Unlock the power of BIM's advanced techniques to elevate your projects to new heights of efficiency, collaboration, and innovation. From streamlined design processes to enhanced project visualization and seamless coordination, BIM empowers your team to achieve excellence at every stage of construction.</p>
                        <a [routerLink]="['/pricing']" class="btn btn-secondary py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft">Get Started</a>
                        <a href="mailto:contact@sg-bim.com?subject=Contact" class="btn btn-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a>
                    </div>
                    <div class="col-lg-6 text-center text-lg-start">
                        <img class="img-fluid animated zoomIn" src="assets/images/logo.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Navbar & Hero End -->


    <!-- Feature Start -->
    <div class="container-xxl py-5">
        <div class="container py-5 px-lg-5">
            <div class="row g-4">
                <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="feature-item bg-light rounded text-center p-4">
                        <i class="bi fa-3x bi-dice-1 text-primary mb-4"></i>
                        <h5 class="mb-3">Contact Us</h5>
                        <p class="m-0">Start your process by writing to us at <a href="mailto:contact@sg-bim.com?subject=Contact">contact@sg-bim.com</a>. Upon receipt of your email, we will contact you as soon as possible.</p>
                    </div>
                </div>
                <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="feature-item bg-light rounded text-center p-4">
                        <i class="bi fa-3x bi-dice-2 text-primary mb-4"></i>
                        <h5 class="mb-3">Let's plan</h5>
                        <p class="m-0">We will then make an appointment with your teams to assess your needs.</p>
                    </div>
                </div>
                <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="feature-item bg-light rounded text-center p-4">
                        <i class="bi fa-3x bi-dice-3 text-primary mb-4"></i>
                        <h5 class="mb-3">Let's go</h5>
                        <p class="m-0">We will begin configuration work on your project and train your teams on the SG BIM product.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Feature End -->

    <!-- Footer Start -->
    <div class="container-fluid bg-primary text-light footer wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-5 px-lg-5">
            <div class="row g-5">
                <div class="col-md-6 col-lg-4">
                    <p class="section-title text-white h5 mb-4">Address<span></span></p>
                    <p><i class="fa fa-map-marker-alt me-3"></i>Nuns' Island Boulevard, Montreal, CANADA</p>
                    <!--<p><i class="fa fa-phone-alt me-3"></i>+012 345 67890</p>-->
                    <p><i class="fa fa-envelope me-3"></i>contact@sg-bim.com</p>
                    <div class="d-flex pt-2">
                        <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-twitter"></i></a>
                        <!--<a class="btn btn-outline-light btn-social" href=""><i class="fab fa-facebook-f"></i></a>-->
                        <!--<a class="btn btn-outline-light btn-social" href=""><i class="fab fa-instagram"></i></a>-->
                        <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <p class="section-title text-white h5 mb-4">Quick Link<span></span></p>
                    <a class="btn btn-link" href="mailto:contact@sg-bim.com?subject=Contact">Contact Us</a>
                    <a class="btn btn-link" href="">Privacy Policy</a>
                    <a class="btn btn-link" href="">Terms & Condition</a>
                    <!--<a class="btn btn-link" href="">Career</a>-->
                </div>
                <div class="col-md-6 col-lg-4">
                    <p class="section-title text-white h5 mb-4">Newsletter<span></span></p>
                    <p>Stay ahead of the curve with our newsletter – your key to unlocking exclusive insights, expert tips, and exciting updates delivered right to your inbox.</p>
                    <subscribe-newsletter-directive></subscribe-newsletter-directive>
                </div>
            </div>
        </div>
        <div class="container px-lg-5">
            <div class="copyright">
                <div class="row">
                    <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a class="border-bottom" href="#">SG BIM</a>, All Right Reserved. 
                        
                        <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
                        Designed By <a class="border-bottom" href="https://sg-bim.com">SG BIM</a>
                    </div>
                    <div class="col-md-6 text-center text-md-end">
                        <div class="footer-menu">
                            <a [routerLink]="['/']">Home</a>
                            <!--<a href="">Cookies</a>-->
                            <a href="">Help</a>
                            <!--<a href="">FQAs</a>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer End -->


    <!-- Back to Top -->
    <a href="#" class="btn btn-lg btn-secondary btn-lg-square back-to-top"><i class="bi bi-arrow-up"></i></a>
</div>