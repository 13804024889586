export const environment = {
  production: false,
  mongoapi: {
    appId: 'data-buwvj',
    userApplicationKey: 'NzXtroMATixD3hRgVD4RfzjbRSo2K5dahUq5kN2SySQiC0IWmM718ZXFyLv8KYnY',
    authenticationUrl: 'https://realm.mongodb.com/api/client/v2.0/app',
    applicationKey: 'yUobeonsiO9xTfDqlFdtRR1047nVGA5WoLCmt0noeiiIGZ31ViIRQdLReD2PtZ4s',
    apiFindUrl: 'https://us-east-2.aws.data.mongodb-api.com/app/data-buwvj/endpoint/data/v1/action/find',
    apiInsertOneUrl: 'https://us-east-2.aws.data.mongodb-api.com/app/data-buwvj/endpoint/data/v1/action/insertOne',
    apiFindOneUrl: 'https://us-east-2.aws.data.mongodb-api.com/app/data-buwvj/endpoint/data/v1/action/findOne',
    apiUpdateOneUrl: 'https://us-east-2.aws.data.mongodb-api.com/app/data-buwvj/endpoint/data/v1/action/updateOne',
    apiDeleteOneUrl: 'https://us-east-2.aws.data.mongodb-api.com/app/data-buwvj/endpoint/data/v1/action/deleteOne',
    dataSource: 'Cluster0',
    database: 'dev-sg-bim',
  },
  chunkSize: 500000,
  firebase: {
    apiKey: "AIzaSyDq_4xo-0oMX5tEn8Y4_oID_r-v9qJktTI",
    authDomain: "bimosoft-2f86c.firebaseapp.com",
    projectId: "bimosoft-2f86c",
    storageBucket: "bimosoft-2f86c.appspot.com",
    messagingSenderId: "148533469075",
    appId: "1:148533469075:web:140acb5b99f0bc6affd266",
    measurementId: "G-PL6E6KNWWT"
  },
  autodesk: {
    authentication: {
      url: 'https://developer.api.autodesk.com/authentication/v1/authenticate',
      clientId: 'm0SvgCZqIbYc9XUlm0OiwUrLY3g1MvHm',
      clientSecret: 'ssM9aRHiInRw1hmq',
      grantType: 'client_credentials'
    },
    bucket: {
      url: 'https://developer.api.autodesk.com/oss/v2/buckets'
    }
  }
};
