import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent {

  constructor() {}

  async ngOnInit() {
    console.log('DashboardComponent.ngOnInit: Start to initialize dashboard');
  }

  async ngAfterContentInit() {
    console.log('DashboardComponent.ngAfterContentInit: Start to draw dashboard after init');
    const progressBarElements = document.querySelectorAll('.progress-bar');
    progressBarElements.forEach(progressBar => {
      const width = progressBar.getAttribute('aria-valuenow') + '%';
      (progressBar as HTMLElement).style.width = width;
    });
  }
}
