import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

@Injectable({
  providedIn: 'root'
})
export class UsersServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

    public async subscribeToNewsletter(email: string): Promise<boolean> {
      console.log('UsersServices.subscribeToNewsletter: Start to subscribe to newsletter', email);
  
      const userData = { 
        email: email, 
        modification: new Date(),
        creation: new Date() 
      };
  
      return new Promise<boolean>(async (resolve, reject) => {
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('UsersServices.subscribeToNewsletter : Token', data);
          token = data;
        });
  
        const insertUrl = `${environment.mongoapi.apiInsertOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<boolean>(insertUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `newsletter-subscriptions`, document: userData }, httpOptions).toPromise().then((data: any) => {
          console.log('UsersServices.subscribeToNewsletter : Useer subscribed', data);
          resolve(data.insertedId !== null && data.insertedId !== undefined);
        });
      });
  
    }
}
