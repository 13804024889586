import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';

import { MatSidenav } from '@angular/material/sidenav';
import { List } from 'linqts';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SettingsServices } from './services/commons/settings.services';
import { LocalStorageServices } from './services/commons/local.storage.services';
import { DataAccessServices } from './services/data.access.services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    public settingsServices: SettingsServices,
    private localStorageServices: LocalStorageServices,
    public dataAccessServices: DataAccessServices,
  ) {
    this.settingsServices.initialize();
  }

  public async ngOnInit() {
  
  }
}
