import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SubscribeNewsletterDirective } from './subscribe.newsletter.directive';

@NgModule({
  declarations: [
    SubscribeNewsletterDirective
  ],
  imports: [
    CommonModule,
    FormsModule 
  ],
  exports: [
    SubscribeNewsletterDirective
  ]
})
export class SharedModule { }