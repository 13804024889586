import { Injectable, EventEmitter, OnDestroy, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataAccessServices implements OnDestroy {

  public cacheRefreshed: EventEmitter<boolean> = new EventEmitter();
  public iscacherefreshed: boolean = false;

  constructor() {}

  public ngOnDestroy(): void {
  }

  public async initialize(): Promise<boolean> {
    console.log('DataAccessServices.initialize: Start to initialize data');
    
    return new Promise<boolean>(async (resolve, reject) => {
      this.iscacherefreshed = true;
      this.cacheRefreshed.emit(this.iscacherefreshed);
      resolve(this.iscacherefreshed);
    });
  }

  public async refresh(): Promise<boolean> {
    console.log('DataAccessServices.refresh: Start to refresh data');

    return new Promise<boolean>(async (resolve, reject) => {
      this.cacheRefreshed.emit(true);
      this.iscacherefreshed = true;
      resolve(true);
    });
  }

  public isCacheRefreshing(): boolean {
    return this.iscacherefreshed == false;
  }
}
