import { Component } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html'
})
export class PricingComponent {

  constructor() {
   }

  async ngOnInit() {
    console.log('PricingComponent.ngOnInit: Start to initialize pricing');
  }
}
