import { Component } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html'
})
export class ServicesComponent {

  constructor() {
   }

  async ngOnInit() {
    console.log('ServicesComponent.ngOnInit: Start to initialize services');
  }

  async ngAfterContentInit() {
    console.log('ServicesComponent.ngAfterContentInit: Start to draw services after init');
    
    const filters = document.querySelectorAll('#portfolio-flters li');
    filters.forEach(filter => {
      filter.addEventListener('click', () => {
        filters.forEach(f => {
          f.classList.remove('active');
        });
        filter.classList.add('active');
        const dataFilterValue = filter.getAttribute('data-filter');
        console.log('ServicesComponent.ngAfterContentInit: dataFilterValue', dataFilterValue);
        const portfolioContainer = document.querySelector('.portfolio-container');
        const portfolioItems = portfolioContainer.querySelectorAll('.portfolio-item');

        portfolioItems.forEach(item => {
          const portfolioItem = item as HTMLElement;
          if (dataFilterValue === '*' || item.classList.contains(dataFilterValue.substring(1))) {
            portfolioItem.style.display = 'block';
          } else {
            portfolioItem.style.display = 'none';
          }
        });
      });
    });
  }
}
